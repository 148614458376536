<!-- @format -->

<template>
    <div class="flex gst-checkout">
        <HeaderWithCountDown
            :date-time-till="cart.expireAt"
            @finish="expire()" />
        <div
            v-if="cart && cart.id"
            class="flex content-max-width px-2">
            <div>
                <WizardIndicator
                    :steps="steps"
                    :current-step="currentStep"
                    @go-to-step="busEventsWizard.$emit('go-to-step', $event)" />
            </div>

            <div
                v-if="!loading"
                class="row d-flex flex-row"
                :class="$vuetify.breakpoint.smAndDown && 'no-gutters'">
                <div class="col-12 col-md-7 mb-4 d-flex flex-column">
                    <BannerInfo
                        :payment-method="paymentModel.paymentMethod"
                        :points="bonusPointsEarn"
                        :currency="loyaltyCurrency || currency"
                        :is-loyalty-currency-type-money="isLoyaltyCurrencyTypeMoney"
                        class="mb-4"
                        :class="stepClass" />
                    <WizardControl
                        :steps="steps"
                        :bus-events="busEventsWizard"
                        :control-by-route="false"
                        :destroy-steps="false"
                        @change-index="onWizardControlChangeIndex($event)">
                        <Step1Summary
                            slot="step0"
                            :step="steps[0]"
                            :cart-collections="cartCollections"
                            :order="order"
                            :payment-model="paymentModel"
                            :has-health-check="hasHealthCheck"
                            :shipping-model="shippingModel"
                            :is-source-tmr="isSourceTmr"
                            @next="busEventsWizard.$emit('next')" />
                        <Step2Delivery
                            slot="step1"
                            :step="steps[1]"
                            :customer-model="customerModel"
                            :shipping-model="shippingModel"
                            :agree-model="agreeModel"
                            :has-health-check="hasHealthCheck"
                            :countries="countries"
                            :user-is-auth="userIsAuth"
                            @back="busEventsWizard.$emit('back')"
                            @next="onNextStep2DeliveryDo" />
                        <Step3Payment
                            slot="step2"
                            :step="steps[2]"
                            :bus-events-parent="busEvents"
                            :payment-client-model="paymentClientModel"
                            :customer-model="customerModel"
                            :cart-collection="cartCollections[0]"
                            :order="order"
                            :payment-model="paymentModel"
                            :shipping-model="shippingModel"
                            :total-bonus-points-redeem="bonusPointsRedeem"
                            :user-bonus-points-current="userBonusPointsCurrent"
                            :show-split-payment="showSplitPayment"
                            :current-step="currentStep"
                            :bus-event-progress-step="busEventProgressStep"
                            :is-source-tmr="isSourceTmr"
                            @open-authorization="onOpenClientPaymentAuthorizationDo"
                            @close-authorization="onCloseClientPaymentAuthorizationDo"
                            @input="updatePaymentModel"
                            @loading-payment-provider-error="onLoadingPaymentProviderError"
                            @back="busEventsWizard.$emit('back')"
                            @next="save" />
                    </WizardControl>
                </div>
                <div
                    v-if="$vuetify.breakpoint.mdAndUp"
                    class="col-12 col-md-5 d-flex flex-column">
                    <Sidebar
                        :current-step="currentStep"
                        :cart-collections="cartCollections"
                        :order="order"
                        :payment-model="paymentModel"
                        :shipping-model="shippingModel"
                        :has-health-check="hasHealthCheck" />
                </div>
            </div>
        </div>
        <PaymentProviderDownOverlay v-if="paymentClientModel.errorContacting" />
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import apiConstants from '@core/utils/constants/api';
import ticketConstants from '@core/utils/constants/ticket';
import { calculateBonusPointsEarn as bonusPointsUtilsCalculateBonusPointsEarn } from '@core/utils/bonusPointsUtils';
import {
    sort as shippingOptionsUtilsSort,
    findOneElectronicTicketItem as shippingOptionsUtilsFindOneElectronicTicketItem,
} from '@core/utils/shippingOptionsUtils';
import { isResaleSourceTmr as isResaleSourceTmrUtil } from '@core/utils/ticketUtils';
import apiPaymentClient from '@core/api/apiPaymentClient';
import apiServicePayments from '@core/api/apiServicePayments';
import statesStoreModule from '@core/store/modules/states';
import countriesStoreModule from '@core/store/modules/countries';
import WizardControl from '@core/shared/components/wizard/Control.vue';
import WizardIndicator from '@core/shared/components/wizard/Indicator.vue';
import PaymentProviderDownOverlay from '@core/shared/components/overlays/PaymentProviderDownOverlay.vue';
import WarnBeforeLeave from '@core/mixins/WarnBeforeLeave';
import zIndex from '@core/scss/mixins/_z-index-export-js.scss';
import DynamicStoreModule from '@core/mixins/DynamicStoreModule';
import { getFromApiResponse as notificationUtilsGetFromApiResponse } from '@core/utils/notificationUtils';
import paymentConstant from '@core/utils/constants/payment';

import {
    BannerInfo,
    HeaderWithCountDown,
    Sidebar,
    Step1Summary,
    Step2Delivery,
    Step3Payment,
} from './TheCheckoutWizard/index';

const STORE_NAME = 'cart';
const STORE_COUNTRIES = 'countries';

export default {
    name: 'TheCheckoutWizard',
    components: {
        HeaderWithCountDown,
        Sidebar,
        Step1Summary,
        Step2Delivery,
        Step3Payment,
        WizardControl,
        WizardIndicator,
        PaymentProviderDownOverlay,
        BannerInfo,
    },
    i18nOptions: {
        namespaces: 'main',
        keyPrefix: 'views.cart.theCheckoutWizard',
    },
    testIdOptions: {
        keyPrefix: 'checkoutWizard',
    },
    mixins: [WarnBeforeLeave, DynamicStoreModule],
    data() {
        const steps = [
            {
                hidden: false,
                labelIndicator: this.$t('indicator.labels.step1Summary'),
            },
            {
                hidden: false,
                labelIndicator: this.$t('indicator.labels.step2Delivery'),
            },
            {
                hidden: false,
                labelIndicator: this.$t('indicator.labels.step3Payment'),
            },
        ].map((item, index) => {
            item.index = index;
            item.slot = 'step' + index;
            return item;
        });

        return {
            loading: true,
            steps: steps,
            currentStep: 0,
            busEventsWizard: new Vue(),
            busEvents: new Vue(),
            busEventProgressStep: new Vue(),
            customerModel: {
                firstName: '',
                lastName: '',
                address: '',
                city: '',
                zip: '',
                state: '',
                country: 'US',
                phone: '',
                email: '',
                memberId: '',
            },
            shippingModel: {
                loading: true,
                optionId: null,
                options: [],
                lastSubmittedOptionId: null,
                globalElectronicShippingAvailable: false,
            },
            agreeModel: {
                terms: false,
                healthCheck: true,
            },
            paymentClientModel: {
                token: null,
                providerId: null,
                errorContacting: false,
            },
            paymentModel: {
                points: 0,
                amount: 0,
                shippingFee: 0,
                shippingTax: 0,
                total: 0,
                paymentMethod: paymentConstant.METHODS.CARD,
            },
            totals: {
                bonusPointsEarnWithShipping: 0,
                bonusPointsRedeemWithShipping: 0,
            },
            checkoutResponse: false,
            testEventId: 106710000,
            countries: [],
            statePurchase: 0,
            needsRefreshOnExit: false,
            browserHistoryLength: 0,
        };
    },
    computed: {
        ...mapGetters({
            isCartExpired: `${STORE_NAME}/isExpired`,
            isCartSubmitted: `${STORE_NAME}/isSubmitted`,
            isCartSubmitting: `${STORE_NAME}/isCartSubmitting`,
            isLoyaltyCurrencyTypeMoney: 'appTenant/isLoyaltyCurrencyTypeMoney',
            userIsAuth: 'user/isAuth',
            userEmail: 'user/wallet/email',
            userBalance: 'user/wallet/balance',
            userMemberId: 'user/wallet/memberId',
        }),
        ...mapState({
            cart: (state) => state[STORE_NAME].current,
        }),
        routeCartId() {
            return this.$route.params.cartId;
        },
        cartCollections() {
            return this.cart.collections;
        },
        order() {
            return this.cart.order;
        },
        currency() {
            return this.order.currency;
        },
        loyaltyCurrency() {
            return this.order.loyaltyCurrency;
        },
        hasResaleItems() {
            return !!this.cartCollections.find(
                (collection) => collection.items[0].selection === ticketConstants.TYPES.RESALE
            );
        },
        hasHealthCheck() {
            return !!this.cartCollections[0].info.healthCheck;
        },
        hasUserMemberId() {
            return this.userIsAuth && this.userMemberId;
        },
        hasUserEmail() {
            return this.userIsAuth && this.userEmail;
        },
        stepClass() {
            return `gst_checkout__step${this.currentStep}`;
        },
        cartShippingOptionId() {
            return this.cartCollections[0]?.shippingId;
        },
        cartCollection() {
            return this.cartCollections[0];
        },
        bonusPoints() {
            return this.cart.order.earnPoints;
        },
        bonusPointsRedeem() {
            return this.totals.bonusPointsRedeemWithShipping || this.cart.order.redeemPoints;
        },
        totalBasePrice() {
            return this.order.price ? this.order.price.basePrice : 0;
        },
        totalFeePrice() {
            return Math.round((this.order.totalPrice - this.totalBasePrice) * 100) / 100;
        },
        bonusPointsEarnRate() {
            return this.bonusPoints / Math.round(this.totalBasePrice);
        },
        userBonusPointsCurrent() {
            return this.userIsAuth ? this.userBalance : 0;
        },
        bonusPointsEarn() {
            const { bonusPointsEarnWithShipping } = this.totals;

            if (!this.paymentModel.points) {
                return bonusPointsEarnWithShipping || this.bonusPoints;
            }
            return bonusPointsUtilsCalculateBonusPointsEarn(
                this.paymentModel.amount,
                this.totalTaxes,
                this.bonusPointsEarnRate
            );
        },
        totalTaxes() {
            const items = [this.totalFeePrice, this.shipping.total];

            return items.reduce((total, currentValue) => total + currentValue);
        },
        total() {
            const { order, shipping, cartShippingOptionId } = this;
            if (order) {
                const totalWithoutShipping = order.totalPrice - order.totalDelivery;

                if (this.currentStep === 0 && !cartShippingOptionId) {
                    return totalWithoutShipping;
                }
                return totalWithoutShipping + shipping.total;
            }
            return 0;
        },
        shipping() {
            const { fee, tax } = this.selectedShippingOption
                ? this.selectedShippingOption.totals
                : { fee: 0, tax: 0 };
            const total = fee + tax;

            return {
                fee,
                tax,
                total,
            };
        },
        selectedShippingOption() {
            const { shippingModel } = this;
            return shippingModel.options.find((value) => value.id === shippingModel.optionId);
        },
        showSplitPayment() {
            return this.cartCollections[0].productId === this.testEventId;
        },
        isSourceTmr() {
            return isResaleSourceTmrUtil(
                this.cartCollection.items[0].selection,
                this.cartCollection.eventSource
            );
        },
    },
    watch: {
        'customerModel.country': {
            handler: function (value, oldValue) {
                if (value) {
                    if (value !== oldValue) {
                        this.loadShippingOptionsForCountry();
                    }
                } else {
                    this.clearShippingOptions();
                }
            },
            deep: true,
        },
        total: {
            handler: function (newValue) {
                if (newValue) {
                    this.paymentModel.total = this.total;
                    this.paymentModel.amount = this.total;
                }
            },
            deep: true,
        },
        shipping: {
            handler: function (newValue) {
                if (newValue) {
                    this.paymentModel.shippingFee = this.shipping.fee;
                    this.paymentModel.shippingTax = this.shipping.tax;
                }
            },
            deep: true,
        },
        'paymentModel.paymentMethod': {
            handler: function (value) {
                if (value) this.initPaymentClient();
            },
        },
    },

    methods: {
        ...mapActions({
            notificationError: 'notification/error',
            showLoadingOverlay: 'appState/setLoading',
            checkoutCart: 'cart/checkout',
            setCurrentStep: 'cart/setCheckoutWizardCurrentStep',
            instantDeleteCurrentCart: 'cart/instantDeleteCurrentCart',
            clearCart: 'cart/clear',
            addShippingOption: 'cart/addShippingOption',
            getShippingOptions: 'cart/getShippingOptions',
            getCart: 'cart/get',
            getCountries: `${STORE_COUNTRIES}/getRaw`,
            getUserWallet: 'user/wallet/get',
        }),
        expire() {
            const doExpire = () => {
                this.unbindWarnBeforeYouLeaveMixin();
                this.showExpireErrorMessage();
                this.$router.replace({ name: 'home' });
            };
            if (this.isCartSubmitting) {
                const unwatch = this.$watch('isSubmitting', (value) => {
                    if (!value && this.cart.id) {
                        doExpire();
                    }
                    unwatch();
                });
            } else {
                doExpire();
            }
        },
        updatePaymentModel(value) {
            this.paymentModel.points = value.points || 0;
            this.paymentModel.amount = value.amount || 0;
        },
        async save({ fnPayment, isSplitPaymentMethod }) {
            const paymentModel = { ...this.paymentModel };

            const getPayload = (card, paymentType) => {
                const {
                    firstName,
                    lastName,
                    email,
                    address,
                    zip,
                    city,
                    state,
                    country,
                    phone,
                    memberId,
                } = this.customerModel;
                const invoiceId = card?.invoiceId;

                const ret = {
                    items: this.cartCollections.map((item) => {
                        return { totalPrice: item.totalPrice, productId: item.productId };
                    }),
                    user: {
                        firstName,
                        lastName,
                        email,
                    },
                    address: Object.assign(
                        {
                            street: address,
                            city,
                            zip,
                            countryCode: country,
                            stateCode: state,
                        },
                        this.hasResaleItems ? { phone } : null
                    ),
                    card: {
                        ...card,
                        paymentType,
                    },
                    externalAccountNumber: memberId,
                    redeem: {
                        money: paymentModel.amount,
                        points: isSplitPaymentMethod ? paymentModel.points : 0,
                    },
                };

                if (invoiceId) {
                    ret.payment = { invoiceId };
                }

                return ret;
            };

            const onCheckoutErrorDo = async (response) => {
                const { code } = response;

                switch (code) {
                    case apiConstants.ERROR_CODES.EVENT_PROVIDER.INVALID_CART_STATE:
                    case apiConstants.ERROR_CODES.TRANSACTION_BLOCKED:
                        this.notificationError(notificationUtilsGetFromApiResponse(response), {
                            root: true,
                        });
                        this.unbindWarnBeforeYouLeaveMixin();
                        this.instantDeleteCurrentCart();
                        this.$router.replace({ name: 'home' });
                        break;
                    default:
                        this.notificationError(notificationUtilsGetFromApiResponse(response), {
                            root: true,
                        });
                        await this.initPaymentClient();
                        break;
                }
            };

            this.$modal.show(
                import('@core/shared/components/modals/ProgressStepAnimationModal.vue'),
                {
                    title: this.$t('progressStepAnimationModal.title'),
                    description: this.$t('progressStepAnimationModal.description'),
                    labels: [
                        this.$t('progressStepAnimationModal.labels.step1Verifying'),
                        this.$t('progressStepAnimationModal.labels.step2Processing'),
                        this.$t('progressStepAnimationModal.labels.step3Finalizing'),
                    ],
                    busEvent: this.busEventProgressStep,
                    endOnRouteChange: false,
                },
                {
                    'content-class': 'gst-checkout-purchase-progress',
                    style: `z-index: ${zIndex.highest}`,
                    'no-click-animation': true,
                    persistent: true,
                    scrollable: false,
                    fullscreen: true,
                    'hide-overlay': true,
                },
                {
                    open: async () => {
                        const responsePaymentClient = await fnPayment();
                        const error = !responsePaymentClient.success;
                        const cardInfo = responsePaymentClient.data;
                        const paymentProviderType = responsePaymentClient.type;
                        this.busEventProgressStep.$emit('go-to-step', 3);
                        if (error) {
                            this.busEventProgressStep.$emit('close');
                            this.notificationError(
                                this.$t('_components.step3Payment.messages.formHasError')
                            );
                            this.busEventsWizard.$emit('back');
                        } else {
                            this.busEventProgressStep.$emit('go-to-step', 3);

                            this.checkoutCart({
                                id: this.cart.id,
                                data: getPayload(cardInfo, paymentProviderType),
                            })
                                .then(async (response) => {
                                    if (response.success) {
                                        await this.getUserWallet();
                                        this.checkoutResponse = response;

                                        this.busEventProgressStep.$emit('go-to-end');
                                        this.unbindWarnBeforeYouLeaveMixin();
                                        this.statePurchase = this.statePurchase + 1;

                                        // Show the popup when beforeClose and checkout have been finished
                                        if (this.statePurchase === 2) {
                                            this.$router.replace({ name: 'home' }, () => {
                                                this.openOrderConfirmation(
                                                    this.checkoutResponse,
                                                    paymentModel,
                                                    this.selectedShippingOption
                                                );
                                            });
                                        }
                                    } else {
                                        this.busEventProgressStep.$emit('close');
                                        this.busEventsWizard.$emit('back');
                                        this.busEvents.$emit('save-error');
                                        onCheckoutErrorDo(response);
                                    }
                                })
                                .catch(() => {
                                    this.busEventProgressStep.$emit('close');
                                    this.notificationError(
                                        this.$t('_components.step3Payment.messages.formHasError')
                                    );
                                    this.busEventsWizard.$emit('back');
                                });
                        }
                    },
                    beforeClose: () => {
                        if (this.checkoutResponse) {
                            this.statePurchase = this.statePurchase + 1;

                            if (this.statePurchase === 2) {
                                this.$router.replace({ name: 'home' }, () => {
                                    this.openOrderConfirmation(
                                        this.checkoutResponse,
                                        paymentModel,
                                        this.selectedShippingOption
                                    );
                                });
                            }
                        }
                    },
                }
            );
        },
        openOrderConfirmation(checkoutResponse, paymentModel, shippingOption) {
            this.$router.replace({
                name: 'orderConfirmation',
                params: {
                    uuid: checkoutResponse.uuid,
                    paymentModel,
                    shippingOption,
                    order: checkoutResponse,
                },
            });
        },
        showExpireErrorMessage() {
            this._cartExpiredErrorShowed ||
                this.notificationError(this.$t('_common:messages.cartExpired'));
            this._cartExpiredErrorShowed = true;
        },
        showTransactionBlockedErrorMessage() {
            this.unbindWarnBeforeYouLeaveMixin();
            this.notificationError({
                title: this.$t('_common:messageServerErrorCodes.40081.title'),
                content: this.$t('_common:messageServerErrorCodes.40081.detail'),
            });
            this.$router.replace({ name: 'home' });
        },
        onLoadingPaymentProviderError() {
            this.showLoadingOverlay(false);
            this.unbindWarnBeforeYouLeaveMixin();
            this.notificationError(this.$t('_common:messages.apiPaymentClientContactingError'));
            this.$router.replace({ name: 'home' });
        },
        /**
         * Load shipping options for a country
         *
         * first we will sort the countries.
         * if we have an shipping option on the ticket and that is on the response we will select that
         * If the first option is an electronic shipping options only show that
         * If we have one option select that
         */
        async loadShippingOptionsForCountry() {
            this.shippingModel.loading = true;
            this.shippingModel.optionId = null;
            this.shippingModel.options = [];
            const country = this.customerModel.country;
            const items = await this.getShippingOptions({
                id: this.cart.id,
                data: { countryCode: country },
            });
            //check if country was change during fetch
            if (country === this.customerModel.country) {
                this.shippingModel.options = shippingOptionsUtilsSort(items || []);
                this.shippingModel.optionId = null;
                const isCartShippingOptionInList = !!this.shippingModel.options.find(
                    (value) => value.id === this.cartShippingOptionId
                );
                if (this.cartShippingOptionId && isCartShippingOptionInList) {
                    this.shippingModel.optionId = this.cartShippingOptionId;
                } else {
                    const firstElectronicOption = shippingOptionsUtilsFindOneElectronicTicketItem(
                        this.shippingModel.options
                    );
                    if (firstElectronicOption) {
                        this.shippingModel.options = [firstElectronicOption];
                    }

                    if (this.shippingModel.options.length === 1) {
                        this.shippingModel.optionId = this.shippingModel.options[0].id;
                    }
                }

                this.shippingModel.loading = false;
            }
        },
        clearShippingOptions() {
            this.shippingModel.options = [];
        },
        async onNextStep2DeliveryDo() {
            const { optionId, lastSubmittedOptionId } = this.shippingModel;
            const selectedShipping = this.shippingModel.options.find(
                (option) => option.id === optionId
            );

            if (optionId !== lastSubmittedOptionId) {
                this.showLoadingOverlay(true);
                const responseAddShippingOption = await this.addShippingOption({
                    id: this.cart.id,
                    data: selectedShipping,
                });
                if (responseAddShippingOption) {
                    this.shippingModel.lastSubmittedOptionId = this.shippingModel.optionId;
                    this.totals.bonusPointsEarnWithShipping =
                        responseAddShippingOption.order.earnPoints;
                    this.totals.bonusPointsRedeemWithShipping =
                        responseAddShippingOption.order.redeemPoints;

                    this.busEventsWizard.$emit('next');
                }
            }
            if (this.paymentClientModel.errorContacting) {
                this.showLoadingOverlay(true);
                await this.initPaymentClient();
            }
            this.showLoadingOverlay(false);
            this.busEventsWizard.$emit('next');
        },
        async initPaymentClient() {
            if (!(await apiPaymentClient.loadClient())) {
                this.paymentClientModel.errorContacting = true;
                return this.paymentClientModel;
            }
            const api = await apiServicePayments('');
            const paymentClientResponse = await api.payments.getClientToken(
                this.cart.id,
                this.paymentModel.paymentMethod
            );
            if (!paymentClientResponse.success) {
                this.paymentClientModel.errorContacting = true;
                return this.paymentClientModel;
            }
            this.paymentClientModel.token = paymentClientResponse.data.clientToken;
            this.paymentClientModel.providerId = paymentClientResponse.data.providerId;
            this.paymentClientModel.errorContacting = false;

            return this.paymentClientModel;
        },
        async loadCart() {
            await this.getCart({ id: this.cart.id });
        },
        async loadCountries() {
            const response = await this.getCountries({ filter: { billingCountries: true } });
            if (response.success) {
                this.countries = response.data.list;
            }
        },
        onWizardControlChangeIndex(index) {
            this.setCurrentStep(index);
            this.currentStep = index;
        },
        onOpenClientPaymentAuthorizationDo() {
            this.needsRefreshOnExit = true;
            this.browserHistoryLength = history.length;
        },
        onCloseClientPaymentAuthorizationDo() {
            this.needsRefreshOnExit = false;
            if (this.browserHistoryLength < history.length) {
                !this._destroyed && this.$router.go(this.browserHistoryLength - history.length);
                this.browserHistoryLength = history.length;
            }
        },
    },

    async created() {
        this.registerStoreModule('states', statesStoreModule);
        this.registerStoreModule('countries', countriesStoreModule);

        const checkCart = () => {
            if (!this.cart.id || this.routeCartId != this.cart.id || this.isCartSubmitted) {
                return;
            }

            if (this.isCartExpired()) {
                this.showExpireErrorMessage();
                return;
            }
            return true;
        };

        this.showLoadingOverlay(true);
        await this.loadCountries();
        this.forceWarnBeforeLeaveMixin();
        if (!checkCart()) {
            this.unbindWarnBeforeYouLeaveMixin();
            this.showLoadingOverlay(false);
            this.$router.replace({ name: 'home' });
            return;
        }
        await this.loadCart();
        await this.initPaymentClient();
        await this.loadShippingOptionsForCountry();
        if (!this.hasResaleItems) {
            Vue.delete(this.customerModel, 'phone');
        }
        if (this.cartShippingOptionId) {
            Vue.set(this.shippingModel, 'lastSubmittedOptionId', this.cartShippingOptionId);
        }
        if (this.hasUserMemberId) {
            Vue.set(this.customerModel, 'memberId', this.userMemberId);
        }
        if (this.hasUserEmail) {
            Vue.set(this.customerModel, 'email', this.userEmail);
        }
        this.showLoadingOverlay(false);
        this.loading = false;
    },

    beforeRouteLeave(to, from, next) {
        this.setCurrentStep(0);
        next();
    },
};
</script>
<style scoped lang="scss"></style>
