var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gst-step3-payment-split"},[_c('div',{staticClass:"gst-step3-payment-split__input pa-4"},[_c('div',{staticClass:"gst-step3-payment-split__input-wrapper mb-8 mb-md-2 d-flex"},[_c('div',{staticClass:"gst-step3-payment-split__input-item d-flex"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('DataLoadingOverlay',{staticClass:"gst-step3-payment-split__amount-overlay",attrs:{"absolute":false,"color":"#FFFFFF","opacity":"0.6","show":!_vm.isPointsCorrelated && _vm.isPaymentWidgetLoaded}},[(!_vm.editAmount)?_c('div',{key:"1",staticClass:"gst-step3-payment-split__label gst-step3-payment-split__label-readonly d-flex px-4"},[_vm._v(" "+_vm._s(_vm.paymentAmount)+" ")]):_vm._e(),(_vm.editAmount)?_c('v-text-field',{key:"2",ref:"amountTextField",staticClass:"gst-input--secondary gst-step3-payment-split__textfield",attrs:{"min":"0","max":_vm.maxAmount,"color":"quinary","single-line":"","outlined":"","hide-details":"","readonly":""},on:{"keyup":[function($event){return _vm.enforceMinMax(_vm.$refs.amountTextField.$refs.input)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleEditAmountValue($event)}],"blur":_vm.toggleEditAmountValue,"focus":function($event){return _vm.setNumericInputType('amountTextField')}},model:{value:(_vm.amountValue),callback:function ($$v) {_vm.amountValue=$$v},expression:"amountValue"}}):_vm._e()],1)],1),_c('div',{staticClass:"gst-step3-payment-split__tab-label d-flex"},[_vm._v(" "+_vm._s(_vm.currency)+" ")])],1),_c('div',{class:[
                    'px-5 gst-step3-payment-split__separator d-flex',
                    { 'pt-4 pb-1': _vm.isMobile } ]},[_vm._v(" + ")]),_c('div',{staticClass:"gst-step3-payment-split__input-item d-flex mb-2"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.editPoints)?_c('div',{key:"1",staticClass:"gst-step3-payment-split__label d-flex px-4",on:{"click":_vm.toggleEditPointsValue,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleEditPointsValue($event)}}},[_vm._v(" "+_vm._s(_vm.pointsFormatted)+" ")]):_vm._e(),(_vm.editPoints)?_c('v-text-field',{key:"2",ref:"pointsTextField",staticClass:"gst-step3-payment-split__textfield",attrs:{"min":"0","max":_vm.maxPoints,"color":"primary","single-line":"","outlined":"","rules":[_vm.rules.minValue]},on:{"keyup":[function($event){_vm.enforceMinMax(_vm.$refs.pointsTextField.$refs.input) &&
                                _vm.isTabLabelFocused(_vm.$refs.pointsTextField.$refs.input) &&
                                _vm.isTabLabelError(_vm.$refs.pointsTextField.$refs.input)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.toggleEditPointsValue($event)}],"blur":_vm.toggleEditPointsValue,"focus":function($event){return _vm.setNumericInputType('pointsTextField')}},model:{value:(_vm.value.points),callback:function ($$v) {_vm.$set(_vm.value, "points", $$v)},expression:"value.points"}}):_vm._e()],1),_c('div',{staticClass:"gst-step3-payment-split__tab-label d-flex",class:{
                        'gst-step3-payment-split__tab-label--focus':
                            _vm.isFocusedState && _vm.editPoints,
                        'gst-step3-payment-split__tab-label--error': _vm.isErrorState && _vm.editPoints,
                    }},[_vm._v(" "+_vm._s(_vm.$t('_common:terms.bonusPoint_plural'))+" ")])],1)]),_c('v-slider',{ref:"slider",staticClass:"gst-step3-payment-split__slide u-mouse-pointer pt-md-2",attrs:{"min":"0","max":_vm.maxPoints,"color":"primary","track-color":"quinary","track-fill-color":"primary","hide-details":""},on:{"change":_vm.onSliderChange,"click":function($event){return _vm.switchToReadRangeValue()}},scopedSlots:_vm._u([{key:"thumb-label",fn:function(props){return [_c('div',{staticClass:"gst-step3-payment-split__slide-thumb-label",style:(_vm.getThumbLabelVisibility(props.value))},[_vm._v(" "+_vm._s(_vm.$t('info.sliderMinimumRedeem', { count: _vm.minimumRedemptionPoints, formattedCount: _vm.minimumRedemptionPointsFormatted, }))+" ")])]}}]),model:{value:(_vm.value.points),callback:function ($$v) {_vm.$set(_vm.value, "points", $$v)},expression:"value.points"}}),_c('div',{staticClass:"gst-step3-payment-split__slide-summary text-right text-lowercase d-flex float-end py-4"},[_vm._v(" "+_vm._s(_vm.$t('_common:terms.bonusPointWithCountApplied', { count: _vm.pointsValue, formattedCount: _vm.pointsFormatted, }))+" / "),_c('span',{staticClass:"primary--text xxs-text d-flex"},[_vm._v(" "+_vm._s(_vm.$t('_common:terms.bonusPointWithCountAvailable', { count: _vm.userBonusPointsCurrent, formattedCount: _vm.userBonusPointsCurrentFormatted, }))+" "),_c('BaseTooltip',{attrs:{"text":_vm.$t('tooltips.requiredPoints', {
                            count: _vm.totalBonusPointsRedeem,
                            formattedCount: _vm.totalBonusPointsRedeemFormatted,
                        }),"open-on-click":!_vm.$vuetify.breakpoint.mdAndUp,"open-on-hover":_vm.$vuetify.breakpoint.mdAndUp,"class-content":"gst-base-tooltip text-lowercase u-capitalize-first-letter u-text-transform-initial","class-activator":"pl-1"}},[_c('v-btn',{staticClass:"gst-checkout-step-3-form__tooltip-btn",attrs:{"icon":"","small":"","text":"","ripple":false}},[_c('BaseIcon',{staticClass:"gst-checkout-step-3-form__tooltip-icon",attrs:{"symbol-id":"icons--info_round"}})],1)],1)],1)]),_c('div',{staticClass:"gst-step3-payment-split__info-redeem d-flex flex-row align-center u-alpha-background u-width-100"},[_c('BaseIcon',{staticClass:"mr-1",attrs:{"symbol-id":"icons--info_round"}}),_vm._v(" "+_vm._s(_vm.$t('info.redeem'))+" ")],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }