<template>
    <v-text-field
        :value="value"
        :data-test-id="testId"
        color="primary"
        class="gst-input--secondary gst-input"
        :label="label"
        :error-messages="errors"
        required
        outlined
        maxlength="255"
        @input="$emit( 'input', $event )" />
</template>
<script>
    export default {
        name: 'Step2DeliveryFormEmail',
        props: {
            value: {
                type: String,
                required: true
            },
            testId: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            },
            errors: {
                type: Array,
                default: ( ) => [ ]
            }
        }
    };
</script>