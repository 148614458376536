import {
    required,
    requiredIf,
    email,
    numeric,
    minLength
} from 'vuelidate/lib/validators';
import {
    phoneNumber as validateUtilsPhoneNumber,
    zipCA as validateUtilsZipCA,
    city as validateCity,
    address as validateAddress
} from '@core/utils/validate';
import VuelidateMessages from '@core/mixins/forms/VuelidateMessages';

const ZIP_MIN_LENGTH_US = 5;

export default {
    mixins: [ VuelidateMessages ],
    computed: {
        _validationsMessages( ) {
            return {
                customerModel: {
                    firstName: {
                        required: (  ) => this.$t( '_common:formErrors.requiredField', {  field: this.$t( 'labels.firstName' ) } ),
                    },
                    lastName: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( 'labels.lastName' ) } ),
                    },
                    address: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( 'labels.address' ) } ),
                        noSpecialCharacters: ( ) => this.$t( '_common:formErrors.address', { field: this.$t( 'labels.address' ) } ),
                    },
                    city: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( 'labels.city' ) } ),
                        noSpecialCharacters: ( ) => this.$t( '_common:formErrors.city', { field: this.$t( 'labels.city' ) } ),
                    },
                    zip: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( 'labels.zip' ), interpolation : { escapeValue: false } } ),
                        minLength: ( field, params ) => this.$t( '_common:formErrors.minLength', { length: params.min } ),
                        zip:( ) => this.$t( '_common:formErrors.zip' )
                    },
                    state: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( 'labels.state' ), interpolation : { escapeValue: false } } ),
                    },
                    country: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( 'labels.country' ) } ),
                        requiredShippingOptions: ( ) => this.$t( 'messages.countryShippingOptionsError' )
                    },
                    phone: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( 'labels.phone' ) } ),
                        phoneNumber: ( ) => this.$t( '_common:formErrors.phone' )
                    },
                    email: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( 'labels.email' ) } ),
                        email: ( ) => this.$t( '_common:formErrors.email', { field: this.$t( 'labels.email' ) } )
                    },
                    confirmEmail: {
                        required: ( ) => this.$t( '_common:formErrors.requiredField', { field: this.$t( 'labels.emailConfirm' ) } ),
                        sameAs: ( ) => this.$t( '_common:formErrors.matchFields', { fields: this.$t( '_common:terms.email_plural' ) } ),
                    }
                },
                shippingModel: {
                    optionId: {
                        requiredShippingOptions: ( ) => this.$t( 'main:views.cart.theCheckoutWizard._components.step2Delivery.form.messages.shippingOptionsError' )
                    }
                },
                agreeModel: {
                    termsEventTicket: {
                        checked: ( ) => this.$t( 'main:views.cart.theCheckoutWizard._components.step2Delivery.form.messages.termsError' )
                    },
                    terms: {
                        checked: ( ) => this.$t( 'main:views.cart.theCheckoutWizard._components.step2Delivery.form.messages.termsError' )
                    },
                    healthCheck: {
                        checked: ( ) => this.$t( 'main:views.cart.theCheckoutWizard._components.step2Delivery.form.messages.healthCheckError' )
                    }
                },
            };
        },
        
    },
    validations() {
        const ret = {
            customerModel: {
                firstName: {
                    required
                },
                lastName: {
                    required
                },
                address: {
                    required,
                    noSpecialCharacters: validateAddress
                },
                city: {
                    required,
                    noSpecialCharacters: validateCity
                },
                zip: {
                    required: requiredIf( function ( ) {
                        return !!this.states.length;
                    } )
                },
                state: {
                    required: requiredIf( function ( ) {
                        return !!this.states.length;
                    } )
                },
                country: {
                    required,
                    requiredShippingOptions: function( ) {
                        return this.hasShippingOptions;
                    }
                },
                phone: {
                    required,
                    phoneNumber: validateUtilsPhoneNumber
                },
                email: {
                    required,
                    email
                },
            },
            shippingModel : {
                optionId: {
                    requiredShippingOptions: function ( ) {
                        return !!( this.hasShippingOptions && this.shippingModel.optionId );
                    }
                }
            },
            agreeModel: {
                terms: {
                    checked( val ) {
                        return val;
                    }
                },
                healthCheck: {
                    checked( val ) {
                        return val;
                    }
                }
            }
        };
        if ( !this.isPhoneRequired ) {
            delete ret.customerModel.phone;
        }

        const zipValidationsCA = {
            required,
            minLength: minLength( 6 ),
            zip: validateUtilsZipCA
        };

        if ( this.customerModel.country && this.customerModel.country === 'CA' ){
            Object.assign( ret.customerModel, { zip: zipValidationsCA } );
        }

        if ( this.customerModel.country && this.customerModel.country === 'US' ){
            Object.assign( ret.customerModel, { zip: { ...ret.customerModel.zip, numeric, minLength: minLength( ZIP_MIN_LENGTH_US ) } } );
        }

        return ret;
    }
};